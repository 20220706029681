<template>
  <div>


    <!--    <div @click="$refs.imageinput.click()">-->
    <wameed-input-file-upload
        v-model="uploaded"
        :showDelete="showDelete"
        :delete-icon="'trash-icon'"
        @deleteImage="deleteImage"
        fileType="images"
        field-classes="w-100"
        :rules="rules"
        functionType="front"
        :label="label"
        :input-placeholder="placeholder"
        :input-append-text="$t('form.image.browse')"
        @changed="uploadImageAction"
    />
    <!--      <wameed-text-input-->
    <!--          id="main-image"-->
    <!--          v-model="uploaded"-->
    <!--          readonly-->
    <!--          name="image"-->
    <!--          :label="label"-->
    <!--          :placeholder="placeholder"-->
    <!--          is-append-->
    <!--          append-class="append-btn"-->

    <!--          :append-text="$t('form.image.browse')"-->
    <!--      />-->
    <!--      <trash-icon/>-->
    <!--    </div>-->
    <!--    <input-->
    <!--        ref="imageinput"-->
    <!--        type="file"-->
    <!--        accept="image/png, image/jpeg"-->
    <!--        style="display: none"-->
    <!--        @change="uploadImageAction($event)"-->
    <!--    />-->

    <cropper-modal
        v-if="showCropperModal"
        :visible="showCropperModal"
        :src="cropImageSrc"
        @close="onCropClose"
        @onCrop="onCrop"
        :height="height"
        :fixed="fixed"
        :width="width"
    ></cropper-modal>
  </div>
</template>

<script>

import CropperModal from "@/components/cropperModal.vue";

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    fixed: {
      type: Boolean,
      default: true
    },
    rules: {
      type: String,
      default: ""
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    uploadedImage: {
      type: [Object, String],
      default: null
    },
    width: {
      type: Number,
      default: 2
    },
    height: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: "card"
    },
    getObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadedUrl: "",
      image: "",
      tempImage: "",
      cropImageSrc: "",
      showCropperModal: false
    };
  },
  computed: {
    uploaded() {
      if (this.uploadedImage && this.uploadedImage !== '')
          //check if it object or string
        if (typeof this.uploadedImage === 'object' && "name" in this.uploadedImage)
          return this.uploadedImage.name;
      return this.uploadedImage;
    }
  },
  watch: {
    uploadedImage(val) {
      if (val) {

        this.image = val;
        this.uploadedUrl = val;
      }
    }
  },
  methods: {

    deleteImage() {
      this.image = "";
      this.$emit("changed", this.image);
    },
    uploadImageAction(value) {
      this.setImage(value.url)

    },
    setImage(file) {
      this.image = file;
      this.showCropperModal = true;
      this.cropImageSrc = file;
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type: mime});
    },
    onCrop(croppedImage) {

      this.image = croppedImage;
      this.tempImage = this.image;
      this.showCropperModal = false;
      const formData = new FormData();

      //convert this.image base64 to file blob
      // generate a random file name
      const fileExtension = this.image.split(';')[0].split('/')[1];
      const randomNumber = Math.floor(Math.random() * 1000000000);
      const fileName = `image-${randomNumber}.${fileExtension}`;
      const file = this.dataURLtoFile(this.image, fileName);


      formData.append('file', file);
      if (this.getObject) {
        let _file = {
          name: file.name,
          size: file.size,
          type: file.type,
          url: this.image,
        }
        this.$emit("changed", _file);
      } else {

        this.$store.commit('loadingStart', null, {root: true});
        this.$store.dispatch('utilities/uploadFiles', formData).then(response => {

          this.uploadedUrl = response;
          this.$store.commit('loadingFinish', null, {root: true});
          this.hideCropperModal();
          this.$emit("changed", this.uploadedUrl);
        });
      }


    },
    onCropClose() {
      this.showCropperModal = false;
      this.image = this.tempImage;
      this.hideCropperModal();
    },
    unsetImage() {
      this.image = "";
      this.$emit("changed", this.image);
    },
    hideCropperModal() {
      let modal = document.querySelector(".modal-open");
      if (modal) {
        modal.style = null;
      }
    }
  },
  components: {

    CropperModal
  }
};
</script>